export const CircleArrowRight = () => (
  <svg
    width='10px'
    height='10px'
    viewBox='0 0 10 10'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>back Arrow</title>
    <defs>
      <filter colorInterpolationFilters='auto' id='arrow-filter-1'>
        <feColorMatrix
          in='SourceGraphic'
          type='matrix'
          values='0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0'
        ></feColorMatrix>
      </filter>
      <polygon
        id='path-2'
        points='10.8 5.40000002 3.49800001 5.40000002 6.85200002 2.046 6.00000002 1.2 1.2 6.00000002 6.00000002 10.8 6.84600002 9.95400004 3.49800001 6.60000002 10.8 6.60000002'
      ></polygon>
    </defs>
    <g id='Final' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='010_Mobile_Homescreen' transform='translate(-320.000000, -1043.000000)'>
        <g id='Group-48' transform='translate(0.000000, 733.000000)'>
          <g id='Group-19' transform='translate(0.000000, 283.000000)'>
            <g id='Group-64' transform='translate(254.000000, 19.000000)'>
              <g id='Group-62' transform='translate(10.000000, 4.000000)'>
                <g id='back-Arrow' transform='translate(55.000000, 3.000000)' filter='url(#arrow-filter-1)'>
                  <g transform='translate(6.000000, 6.000000) scale(-1, 1) translate(-6.000000, -6.000000) '>
                    <mask id='mask-3' fill='white'>
                      <use xlinkHref='#path-2'></use>
                    </mask>
                    <use id='Icon' fill='#FF3278' xlinkHref='#path-2'></use>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
