import React from 'react'

import Backdrop from './Backdrop'
import styles from './Header.module.css'
import SideDrawer from './SideDrawer'
import Toolbar from './Toolbar'
import useCustomHeader from './useHeader'

export default function Header() {
  const {
    selectedLanguage,
    isLanguageSelectorOpen,
    isDrawerOpen,
    drawerToggleHandler,
    closeDrawerHandler,
    languageSelectorToggleHandler,
    closeLanguageSelectorHandler,
  } = useCustomHeader()

  return (
    <div>
      <Toolbar
        onDrawerClick={drawerToggleHandler}
        isLanguageSelectorOpen={isLanguageSelectorOpen}
        onLanguageSelectorClick={languageSelectorToggleHandler}
        onLanguageSelectorClose={closeLanguageSelectorHandler}
        selectedLanguage={selectedLanguage}
      />
      <SideDrawer show={isDrawerOpen} onClose={closeDrawerHandler} />
      {isDrawerOpen && <Backdrop onClose={closeDrawerHandler} />}
      <div className={styles.empty}></div>
    </div>
  )
}
