import { I18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { FC } from 'react'

const LinguiProvider =
  (i18n: I18n): FC =>
  props =>
    <I18nProvider i18n={i18n}>{props.children}</I18nProvider>

export default LinguiProvider
