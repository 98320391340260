import Link from 'next/link'
import { FC } from 'react'

import { ArrowRight } from '../../../icons'
import Img from '../../../Img'
import styles from '../SideDrawer.module.css'

interface Props {
  href: string
  imageAlt: string
  imageSrc: string
}

const MainSliderLink: FC<Props> = props => {
  return (
    <Link href={props.href}>
      <a className={styles.link}>
        <Img alt={props.imageAlt} height={40} src={props.imageSrc} width={40} />
        <div className={styles.sideDrawerMainItemTitle}>{props.children}</div>
        <div className={styles.spacer} />
        <ArrowRight />
      </a>
    </Link>
  )
}

export default MainSliderLink
