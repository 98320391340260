import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import Link from 'next/link'
import React from 'react'

import { ArrowRight } from '../../../icons'
import styles from '../SideDrawer.module.css'
const shopPageUrl = process.env.NEXT_PUBLIC_MYSHOP_URL as string

const SubSliderItem = () => (
  <div className={styles.sideDrawerSubItemWrapper}>
    <Link href='/categories'>
      <a className={classNames(styles.sideDrawerSubItem, styles.link)}>
        <div className={styles.sideDrawerSubItemLabel}>
          <Trans>Categories</Trans>
        </div>
        <div className={styles.spacer} />
        <ArrowRight />
      </a>
    </Link>
    <div className={styles.line}></div>
    <Link href='/blogs'>
      <a className={classNames(styles.sideDrawerSubItem, styles.link)}>
        <div className={styles.sideDrawerSubItemLabel}>
          <Trans>Read Blogs</Trans>
        </div>
        <div className={styles.spacer} />
        <ArrowRight />
      </a>
    </Link>
    <div className={styles.line}></div>
    <Link href='/qa'>
      <a className={classNames(styles.sideDrawerSubItem, styles.link)}>
        <div className={styles.sideDrawerSubItemLabel}>
          <Trans>Read QnA</Trans>
        </div>
        <div className={styles.spacer} />
        <ArrowRight />
      </a>
    </Link>
    <div className={styles.line}></div>
    <a className={classNames(styles.sideDrawerSubItem, styles.link)} href={shopPageUrl}>
      <div className={styles.sideDrawerSubItemLabel}>
        <Trans>My Shop</Trans>
      </div>
      <div className={styles.spacer} />
      <ArrowRight />
    </a>
    <div className={styles.line}></div>
  </div>
)

export default SubSliderItem
