import { Trans } from '@lingui/macro'

import styles from './Policies.module.css'

const shopPageUrl = process.env.NEXT_PUBLIC_MYSHOP_URL as string
const aboutPage = `${shopPageUrl}/pages/about`
const privacyPage = `${shopPageUrl}/pages/privacy-policy`
const tncPage = `${shopPageUrl}/pages/terms-of-use`
const returnsPage = `${shopPageUrl}/pages/privacy-policy`

const Policies = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.links}>
        <a className={styles.link} href={aboutPage}>
          <Trans>About</Trans>
        </a>
        <span className={styles.separator}>·</span>
        <a className={styles.link} href={tncPage}>
          <Trans>Terms &amp; Conditions</Trans>
        </a>
        <span className={styles.separator}>·</span>
        <a className={styles.link} href={privacyPage}>
          <Trans>Privacy Policy</Trans>
        </a>
        <span className={styles.separator}>·</span>
        <a className={styles.link} href={returnsPage}>
          <Trans>Return &amp; Refund Policy</Trans>
        </a>
      </div>
    </div>
  )
}

export default Policies
