import { FC } from 'react'

import { ScreenContext } from '../../data/contexts/screen'

const ScreenProvider =
  (screen: string): FC =>
  props =>
    <ScreenContext.Provider value={screen}>{props.children}</ScreenContext.Provider>

export default ScreenProvider
