import Head from 'next/head'
import { FC } from 'react'

import { PageMetadata } from '../../interfaces'

interface Props {
  title: string
  ogTitle: string
  ogImage?: string
  seoData?: PageMetadata
}

const defaultDescription =
  'Healofy was created with a mission to help you experience special motherhood through its products and its inexhaustive and evergrowing babycare tips and more.'

// todo - fix
const origin = process.env.NEXT_PUBLIC_DOMAIN as string
const host = new URL(origin).hostname
const playstoreUrl = 'https://play.google.com/store/apps/details?id=com.healofy'

const defaultImage = 'https://p.healofy.net/web/playstore-cover.jpeg'

const MetaTags: FC<Props> = props => {
  const description = props.seoData?.metaDescription || defaultDescription
  const ogTitle = props.seoData?.title || props.ogTitle
  return (
    <>
      <Head>
        <title>{props.seoData?.title || props.title}</title>
        <meta name='description' content={description} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={props.ogImage || defaultImage} />
        <meta property='og:title' content={ogTitle} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={playstoreUrl} />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:domain' content={host} />
        <meta name='twitter:title' content={ogTitle} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={props.ogImage || defaultImage} />
        <meta name='twitter:url' content={origin} />
      </Head>
    </>
  )
}

export default MetaTags
