import classNames from 'classnames'
import React, { FC } from 'react'

import { Close } from '../../icons'
import HeaderLogo from '../HeaderLogo'
import Policies from './Polices'
import styles from './SideDrawer.module.css'
import MainSliderItem from './sliderItems/MainSliderItem'
import SubSliderItem from './sliderItems/SubSliderItem'

type Props = {
  onClose: () => void
  show: boolean
}

type MainSliderItem = {
  id: string
  imageSrc: string
  title: string
  subTitle: string
  link: string
}

const SideDrawer: FC<Props> = props => {
  return (
    <nav className={classNames(styles.sideDrawer, { [styles.open]: props.show })}>
      <div className={styles.sideDrawerWrapper}>
        <div className={styles.sideDrawerHeader}>
          <HeaderLogo className={styles.toolbarLogo} />
          <div className={styles.spacer} />
          <div onClick={props.onClose} className={styles.sideDrawerClose}>
            <Close />
          </div>
        </div>
        <MainSliderItem />
        <div className={styles.mainLine}></div>
        {<SubSliderItem />}
        <Policies />
      </div>
    </nav>
  )
}

export default SideDrawer
