import Link from 'next/link'
import { homePage } from '../../data/pages'

import styles from './Logo.module.css'

// 486x186
const logoUrl = process.env.NEXT_PUBLIC_FULL_LOGO as string

const Logo = () => {
  return (
    <Link href={homePage}>
      <a>
        <img alt='Healofy' className={styles.logo} height={50} width={138} src={logoUrl} />
      </a>
    </Link>
  )
}

export default Logo
