import { Trans } from '@lingui/macro'
import React from 'react'

import styles from '../SideDrawer.module.css'
import MainSliderLink from './MainSlideLink'

const MainSliderItem = () => (
  <ul className={styles.sideDrawerMainItem}>
    <li>
      <MainSliderLink href='/lifestages/pfp' imageAlt='pfp' imageSrc='https://p.healofy.net/web/navigation/ttc.png'>
        <div className={styles.sideDrawerMainItemMainTitle}>
          <Trans>Planning For</Trans>
        </div>
        <div className={styles.sideDrawerMainItemSubTitle}>
          <Trans>Pregnancy</Trans>
        </div>
      </MainSliderLink>
    </li>
    <li>
      <MainSliderLink href='/lifestages/pregnant' imageAlt='pregnant' imageSrc='https://p.healofy.net/web/navigation/pregnancy.png'>
        <div className={styles.sideDrawerMainItemMainTitle}>
          <Trans>I am</Trans>
        </div>
        <div className={styles.sideDrawerMainItemSubTitle}>
          <Trans>Pregnant</Trans>
        </div>
      </MainSliderLink>
    </li>
    <li>
      <MainSliderLink href='/lifestages/mother' imageAlt='mother' imageSrc='https://p.healofy.net/web/navigation/parenting.png'>
        <div className={styles.sideDrawerMainItemMainTitle}>
          <Trans>I have</Trans>
        </div>
        <div className={styles.sideDrawerMainItemSubTitle}>
          <Trans>a Child</Trans>
        </div>
      </MainSliderLink>
    </li>
  </ul>
)

export default MainSliderItem
