import Link from 'next/link'
import React, { FC } from 'react'

import { languages } from './constants'
import styles from './LanguageSelectModal.module.css'

interface Props {
  selectedLanguage: string
  path: string
}

const LanguageOptions: FC<Props> = ({ selectedLanguage, path }) => (
  <div className={styles.languageSelectModalWrapper}>
    {languages.map(language => (
      <Link key={language.id} href={path} locale={language.value}>
        <a className={styles.languageOption} key={language.id}>
          <input
            checked={selectedLanguage === language.value}
            className={styles.languageOptionRadio}
            id={language.id}
            name='languages'
            readOnly
            type='radio'
            value={language.value}
          ></input>
          <span>{language.label}</span>
        </a>
      </Link>
    ))}
  </div>
)

export default LanguageOptions
