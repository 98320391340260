import React, { FC } from 'react'

import styles from './Backdrop.module.css'

type Props = {
  onClose: () => void
}
const Backdrop: FC<Props> = props => <div className={styles.backdrop} onClick={props.onClose} />

export default Backdrop
