import React from 'react'

import styles from './DrawerToggle.module.css'

type DrawerToggle = {
  onDrawerClick: () => void
}

const DrawerToggle = (props: DrawerToggle) => (
  <button className={styles.toggleButton} onClick={props.onDrawerClick}>
    ☰
  </button>
)

export default DrawerToggle
