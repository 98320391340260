// import { ParsedUrlQuery } from 'querystring'

// import { version as appVersion } from '../../configs/version'
// import { localeContainer, screenContainer } from '../../data/containers'
// import { getDeviceId } from '../../utils/deviceId'
// import * as log from '../../utils/log'
// import { request } from '../request'
import { ClevertapEventData, ClevertapEventName } from './clevertap.events'

// const eventAPI = process.env.NEXT_PUBLIC_API_EVENTS as string

export const utmMap = new Set(['ad_source', 'fbclid', 'origin_source', 'utm_campaign', 'utm_id', 'utm_medium', 'utm_source'])

// export const getUTMHeadersFromQuerystring = (q: ParsedUrlQuery) => {
//   const campaignData = {} as { [k: string]: string | Array<string> }
//   for (const searchKey of utmMap) {
//     const value = q[searchKey]
//     if (value?.length) {
//       campaignData[searchKey] = value
//     }
//   }
//   return campaignData
// }

// export const getUTMHeaders = (searchParams: URLSearchParams) => {
//   const campaignData = {} as { [k: string]: string }
//   for (const searchKey of utmMap) {
//     const value = searchParams.get(searchKey)
//     if (value) {
//       campaignData[searchKey] = value
//     }
//   }
//   return campaignData
// }

// const getUTM = () => {
//   if (typeof window === 'undefined') {
//     return
//   }
//   const searchParams = new URLSearchParams(window.location.search)
//   return getUTMHeaders(searchParams)
// }

// const sendClevertapEventReq = async (evtName: string, data: ClevertapEventData, identity: string) => {
//   log.debug('sending clevertap event', evtName, data)
//   try {
//     await request('post', `${eventAPI}/web`, JSON.stringify({ data, evtName, identity }))
//   } catch (error) {
//     log.error('error sending clevertap event', error, evtName, data)
//   }
// }

export const pushCleverEvent = (_evtName: ClevertapEventName, _evtData: ClevertapEventData) => {
  // todo - enable when switching from clevertap
  // const identity = getDeviceId()
  // log.debug('attempting clevertap event', evtName, evtData)
  // if (typeof window === 'undefined') {
  //   return
  // }
  // const locale = localeContainer.value()
  // const screen = screenContainer.value()
  // if (!screen) {
  //   log.error('no screenname available', evtName, evtData)
  //   return
  // }
  // sendClevertapEventReq(evtName, { ...getUTM(), appVersion, locale, ...evtData, screen, url: window.location.href }, identity)
}
