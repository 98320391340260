import React, { FC } from 'react'

import { NewArrowDown } from '../../../icons'
import LanguageSelectModal from '../../LanguageSelectModal'
import { Locale, localeToLanguage } from '../../LanguageSelectModal/constants'
import styles from '../Toolbar.module.css'

interface Props {
  isOpen: boolean
  selectedLanguage: Locale
  onClick: () => void
  onClose: () => void
}

const ToolBarLanguageButton: FC<Props> = ({ isOpen, selectedLanguage, onClick, onClose }) => (
  <div>
    <button className={styles.toolbarLanguageButton} onClick={() => onClick()}>
      {localeToLanguage(selectedLanguage)}
      <NewArrowDown />
    </button>
    <LanguageSelectModal isOpen={isOpen} onClose={onClose} selectedLanguage={selectedLanguage} />
  </div>
)

export default ToolBarLanguageButton
