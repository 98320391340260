import { FC } from 'react'

interface Props {
  className?: string
}

export const NewArrowDown: FC<Props> = () => (
  <svg width='15px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='Final' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='010_Mobile_Homescreen' transform='translate(-334.000000, -107.000000)' fill='#303030' fillRule='nonzero'>
        <g id='Group-7' transform='translate(40.000000, 87.000000)'>
          <g id='Group-30' transform='translate(224.000000, 4.000000)'>
            <g id='Group-28' transform='translate(14.750000, 12.000000)'>
              <path
                d='M62.5173613,4.74533392 C62.7960807,4.43892181 63.2704234,4.41647241 63.5768356,4.69519179 C63.855392,4.94857305 63.8992686,5.36362655 63.695538,5.66720872 L63.6269777,5.75466608 L60.2158929,9.50466608 C59.9431403,9.80451865 59.4880714,9.82950636 59.1849327,9.57962922 L59.1062765,9.50466608 L55.6951918,5.75466608 C55.4164724,5.44825397 55.4389218,4.97391117 55.7453339,4.69519179 C56.0238904,4.44181054 56.4412325,4.4373291 56.7242168,4.6688203 L56.8048082,4.74533392 L59.661,7.885 L62.5173613,4.74533392 Z'
                id='Path-2'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
