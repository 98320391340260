import { useEffect } from 'react'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import { ClevertapVisibleEvent } from '../../api/analytics/visible.clevertap.events'
import { screenContainer } from '../../data/containers'
import ScreenProvider from './ScreenProvider'

const useScreenName = (screen: string, metadata = {} as ClevertapVisibleEvent) => {
  useEffect(() => {
    screenContainer.set(screen)
    pushCleverEvent('visible_start', metadata)
    return () => pushCleverEvent('visible', metadata)
  }, [screen])

  return ScreenProvider(screen)
}

export default useScreenName
