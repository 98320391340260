import { FC } from 'react'

interface Props {
  className?: string
}

export const Close: FC<Props> = () => (
  <svg width='22px' height='22px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>Close</title>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='mOrder-Details---Return' transform='translate(-136.000000, -662.000000)'>
        <g id='Close_white' transform='translate(136.000000, 662.000000)'>
          <path
            d='M14,0 C6.258,0 0,6.258 0,14 C0,21.742 6.258,28 14,28 C21.742,28 28,21.742 28,14 C28,6.258 21.742,0 14,0 L14,0 Z'
            id='Icon-path'
            fill='#0D0D0D'
            fillRule='nonzero'
          ></path>
          <polygon
            id='Icon-path'
            fill='#FFFFFF'
            points='21 19.026 19.026 21 14 15.974 8.974 21 7 19.026 12.026 14 7 8.974 8.974 7 14 12.026 19.026 7 21 8.974 15.974 14'
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
)
