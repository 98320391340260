import classNames from 'classnames'
import { FC } from 'react'

import styles from './ArrowRight.module.css'

interface Props {
  className?: string
}

export const ArrowRight: FC<Props> = props => (
  <svg className={classNames(styles.arrowRight, props.className)} viewBox='0 0 15 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='018_Hamburger_Menu' transform='translate(-235.000000, -250.000000)' fill='#606060' fillRule='nonzero'>
      <g id='Group-70-Copy' transform='translate(20.000000, 226.000000)'>
        <path
          d='M223.445192,26.4953339 C223.723911,26.1889218 224.198254,26.1664724 224.504666,26.4451918 C224.783223,26.698573 224.827099,27.1136265 224.623369,27.4172087 L224.554808,27.5046661 L219.554808,33.0014512 C219.282056,33.3013038 218.826987,33.3262915 218.523848,33.0764144 L218.445192,33.0014512 L213.445192,27.5046661 C213.166472,27.198254 213.188922,26.7239112 213.495334,26.4451918 C213.77389,26.1918105 214.191233,26.1873291 214.474217,26.4188203 L214.554808,26.4953339 L219,31.382 L223.445192,26.4953339 Z'
          id='Path-2'
          transform='translate(219.000000, 29.748390) rotate(-90.000000) translate(-219.000000, -29.748390) '
        ></path>
      </g>
    </g>
  </svg>
)
