import { i18n } from '@lingui/core'
import { bn, en, gu, hi, kn, ml, mr, ta, te } from 'make-plural/plurals'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { localeContainer } from '../../data/containers'
import { Locale, getLocale } from '../../data/intl'
import LinguiProvider from './LinguiProvider'

i18n.loadLocaleData('bn', { plurals: bn })
i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('fake', { plurals: en })
i18n.loadLocaleData('gu', { plurals: gu })
i18n.loadLocaleData('hi', { plurals: hi })
i18n.loadLocaleData('kn', { plurals: kn })
i18n.loadLocaleData('ml', { plurals: ml })
i18n.loadLocaleData('mr', { plurals: mr })
i18n.loadLocaleData('ta', { plurals: ta })
i18n.loadLocaleData('te', { plurals: te })

export const useLingui = () => {
  const router = useRouter()
  const locale = getLocale(router.locale)

  useEffect(() => {
    const loadLocale = async (locale: Locale) => {
      const { messages } = await import(`../../locale/${locale}/messages.po`)
      i18n.load(locale, messages)
      i18n.activate(locale)
      localeContainer.set(locale || 'en')
    }
    loadLocale(locale)
  }, [locale])

  return LinguiProvider(i18n)
}
