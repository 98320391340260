export const Shop = () => (
  <svg viewBox='0 0 28 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <title>Combined Shape</title>
    <g id='Final' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='010_Mobile_Homescreen' transform='translate(-309.000000, -156.000000)' fill='#FFFFFF'>
        <g id='Group-70' transform='translate(0.000000, 138.000000)'>
          <g id='Group-49' transform='translate(285.000000, 8.000000)'>
            <path
              d='M45.8748388,20.3040508 C46.6658227,20.7272815 47.5705541,21.3076923 48.4997851,21.3076923 C49.1070227,21.3076923 49.6932607,20.8435892 50.2497493,20.6610508 L50.2497493,20.6610508 L50.2497493,29.3846154 C50.2497493,30.2763077 49.4657654,31 48.4997851,31 L48.4997851,31 L46.7498209,31 L46.7498209,22.9230769 L41.4999284,22.9230769 L41.4999284,31 L27.5002149,31 C26.5342346,31 25.7502507,30.2763077 25.7502507,29.3846154 L25.7502507,29.3846154 L25.7502507,20.6416662 C27.1834714,21.09882 28.8109381,21.0083585 30.1251612,20.3040508 C31.7053788,21.1488969 33.7948361,21.1488969 35.3750537,20.3040508 C36.9552714,21.1488969 39.0429787,21.1488969 40.6249463,20.3040508 C42.2069139,21.1488969 44.2928712,21.1488969 45.8748388,20.3040508 Z M36.2500358,22.9230769 L29.2501791,22.9230769 L29.2501791,27.7692308 L36.2500358,27.7692308 L36.2500358,22.9230769 Z M46.7498209,10 C47.0298152,10 47.2923098,10.1292308 47.4673063,10.3392308 L47.4673063,10.3392308 L51.8422167,15.9930769 C51.8947156,16.1384615 51.9472146,16.2838462 51.9998579,16.4292308 C52.0172131,18.2223077 50.4422454,19.6923077 48.4997851,19.6923077 C47.4498066,19.6923077 46.5223256,19.2723077 45.8748388,18.5938462 C45.2273521,19.2723077 44.2998711,19.6923077 43.2498926,19.6923077 C42.199914,19.6923077 41.272433,19.2723077 40.6249463,18.5938462 C39.9774595,19.2723077 39.0499785,19.6923077 38,19.6923077 C36.9500215,19.6923077 36.0225405,19.2723077 35.3750537,18.5938462 C34.727567,19.2723077 33.800086,19.6923077 32.7501074,19.6923077 C31.7001289,19.6923077 30.7726479,19.2723077 30.1251612,18.5938462 C29.4776744,19.2723077 28.5501934,19.6923077 27.5002149,19.6923077 C25.5577546,19.6923077 23.9827869,18.2223077 24.0001421,16.4292308 C24.0527854,16.2838462 24.1052844,16.1384615 24.1577833,15.9930769 L24.1577833,15.9930769 L28.5326937,10.3392308 C28.7076902,10.1292308 28.9701848,10 29.2501791,10 L29.2501791,10 Z'
              id='Combined-Shape'
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
