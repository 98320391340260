export type Locale = 'bn' | 'en' | 'gu' | 'hi' | 'kn' | 'ml' | 'mr' | 'ta' | 'te'

const defaultLocale = 'en' as const

// note - only use as getLocale(router.locale)
export const getLocale = (locale?: string) => (locale as Locale) || defaultLocale

const localeLangMap = {
  bn: 'BANGLA' as const,
  en: 'ENGLISH' as const,
  gu: 'GUJRATI' as const,
  hi: 'HINDI' as const,
  kn: 'KANNADA' as const,
  ml: 'MALYALAM' as const,
  mr: 'MARATHI' as const,
  ta: 'TAMIL' as const,
  te: 'TELUGU' as const,
}

export const localeToLanguage = (locale: Locale) => localeLangMap[locale] || localeLangMap.en

const appengineLanguageMap = {
  BANGLA: 'Bengali' as const,
  BENGALI: 'Bengali' as const,
  ENGLISH: 'English' as const,
  GUJRATI: 'Gujarati' as const,
  GUJARATI: 'Gujarati' as const,
  HINDI: 'Hindi' as const,
  KANNADA: 'Kannada' as const,
  MALYALAM: 'Malayalam' as const,
  MALAYALAM: 'Malayalam' as const,
  MARATHI: 'Marathi' as const,
  TAMIL: 'Tamil' as const,
  TELUGU: 'Telugu' as const,
}

export const toRealWorldLanguage = (language: string) => appengineLanguageMap[language as keyof typeof appengineLanguageMap]
