import React, { FC } from 'react'

import HeaderLogo from '../HeaderLogo'
import { Locale } from '../LanguageSelectModal/constants'
import DrawerToggle from '../SideDrawer/DrawerToggle'
import styles from './Toolbar.module.css'
import ToolBarLanguageButton from './toolbarButton/ToolBarLanguageButton'

type Props = {
  isLanguageSelectorOpen: boolean
  onDrawerClick: () => void
  onLanguageSelectorClick: () => void
  onLanguageSelectorClose: () => void
  selectedLanguage: Locale
}

const ToolBar: FC<Props> = props => {
  return (
    <header className={styles.toolbar}>
      <nav className={styles.toolbarNavigation}>
        <div>
          <DrawerToggle onDrawerClick={props.onDrawerClick} />
        </div>
        <HeaderLogo />
        <div className={styles.spacer} />
        <ToolBarLanguageButton
          isOpen={props.isLanguageSelectorOpen}
          onClick={props.onLanguageSelectorClick}
          onClose={props.onLanguageSelectorClose}
          selectedLanguage={props.selectedLanguage}
        />
      </nav>
    </header>
  )
}

export default ToolBar
