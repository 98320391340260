import classNames from 'classnames'
import React, { DetailedHTMLProps, FC, ImgHTMLAttributes, useCallback, useState } from 'react'

import { captureException } from '../../api/analytics/sentry'
import { contextNames } from '../../data/errors'
import { ErrorWithData } from '../../interfaces'
import * as log from '../../utils/log'
import Loader from '../Loader'
import styles from './Img.module.css'

interface Props extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  errorClassName?: string
}

const Img: FC<Props> = ({ errorClassName, height, width, ...props }) => {
  const [errored, setErrored] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const onError = useCallback(
    error => {
      log.error('unable to load', props.src, error)
      setErrored(true)
      captureException(error as ErrorWithData<undefined>, contextNames.image, { extra: { src: props.src } })
    },
    [props.src]
  )

  const image = (
    <img
      className={classNames(props.className, { [styles.hidden]: isLoading })}
      height={!isLoading ? height : undefined}
      onError={onError}
      onLoad={() => setIsLoading(false)}
      width={!isLoading ? width : undefined}
      alt=''
      {...props}
    />
  )

  const loaderClasses = classNames(props.className, errorClassName && { [errorClassName]: errored })
  const loader = <Loader className={loaderClasses} height={height} width={width} />

  if (!props.src) {
    return loader
  }

  return (
    <>
      {isLoading && !errored ? loader : null}
      {errored ? loader : image}
    </>
  )
}

export default Img
