import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import Modal from 'react-modal'

import { Close } from '../../icons'
import LanguageOptions from './LanguageOptions'
import styles from './LanguageSelectModal.module.css'

type Props = {
  onClose: () => void
  selectedLanguage: string
  isOpen: boolean
}

Modal.setAppElement('#__next')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 1000,
  },
}

const LanguageSelectModal: FC<Props> = props => {
  const router = useRouter()
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        props.onClose()
      }}
      style={customStyles}
    >
      <div>
        <button style={{ float: 'right', background: 'none', border: 'none', cursor: 'pointer' }} onClick={() => props.onClose()}>
          <Close />
        </button>
      </div>
      <div className={styles.languageSelectModalTitle}>{t`SELECT LANGUAGE:`}</div>
      <LanguageOptions selectedLanguage={props.selectedLanguage} path={router.asPath} />
    </Modal>
  )
}

export default LanguageSelectModal
