import React, { FC } from 'react'

import Logo from '../../Logo'
import styles from './Logo.module.css'

type Props = {
  className?: string
}

const HeaderLogo: FC<Props> = props => (
  <div className={props.className ? `${styles.toolbarLogo} ${props.className}` : styles.toolbarLogo}>
    <Logo />
  </div>
)

export default HeaderLogo
