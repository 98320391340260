import classNames from 'classnames'
import { FC } from 'react'

import styles from './Loader.module.css'

type Props = {
  className?: string
  height?: number | string
  width?: number | string
}

const Loader: FC<Props> = props => {
  const height = `${props.height}px`
  const width = `${props.width}px`
  const style = { height, minHeight: height, minWidth: width, width }
  return <div className={classNames(styles.placeholderItem, props.className)} style={style}></div>
}

export default Loader
