import { FC } from 'react'

interface Props {
  color?: string
}

export const BackwardArrow: FC<Props> = () => (
    <svg width="17px" height="17px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="010_Mobile_Homescreen" transform="translate(-24.000000, -369.000000)" fill="#FF3278">
                <g id="Group-45" transform="translate(-276.000000, 226.000000)">
                    <g id="Group-39-Copy" transform="translate(307.000000, 150.000000) scale(-1, 1) translate(-307.000000, -150.000000) translate(284.000000, 127.000000)">
                        <polygon id="Icon" transform="translate(23.000000, 23.000000) scale(-1, 1) translate(-23.000000, -23.000000) " points="29.9 22.1375 19.3020331 22.1375 23.9754965 17.316125 22.7883197 16.1 16.1 23 22.7883197 29.9 23.9671361 28.683875 19.3020331 23.8625 29.9 23.8625"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
