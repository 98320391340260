import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

import { getLocale } from '../../data/intl'

export default function useHeader() {
  const router = useRouter()
  const selectedLanguage = getLocale(router.locale)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false)

  const drawerToggleHandler = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen)
  }, [isDrawerOpen])

  const closeDrawerHandler = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  const languageSelectorToggleHandler = useCallback(() => {
    setIsLanguageSelectorOpen(!isLanguageSelectorOpen)
  }, [isLanguageSelectorOpen])

  const closeLanguageSelectorHandler = useCallback(() => {
    setIsLanguageSelectorOpen(false)
  }, [])

  return {
    selectedLanguage,
    isLanguageSelectorOpen,
    isDrawerOpen,
    drawerToggleHandler,
    closeDrawerHandler,
    languageSelectorToggleHandler,
    closeLanguageSelectorHandler,
  }
}
