import classNames from 'classnames'
import { FC } from 'react'

import styles from './ForwardArrow.module.css'

interface Props {
  className?: string
}

export const ForwardArrow: FC<Props> = props => (
  <svg xmlns='http://www.w3.org/2000/svg' className={classNames(styles.forwardArrow, props.className)} viewBox='0 0 17 17'>
    <g>
      <path
        d='M18.467 9.233L5.981 9.233 11.716 3.498 10.259 2.052 2.052 10.259 10.259 18.467 11.706 17.02 5.981 11.285 18.467 11.285z'
        transform='translate(-1520.000000, -1364.000000) translate(0.000000, 1286.000000) translate(359.000000, 54.000000) translate(1046.000000, 8.000000) translate(19.000000, 9.000000) translate(104.302469, 15.388889) scale(-1, 1) translate(-104.302469, -15.388889) translate(94.043210, 5.129630)'
      />
    </g>
  </svg>
)
