export const languages = [
  {
    id: 'HINDI',
    label: 'हिंदी',
    value: 'hi',
  },
  {
    id: 'KANNADA',
    label: 'ಕನ್ನಡ',
    value: 'kn',
  },
  {
    id: 'MALAYALAM',
    label: 'മലയാളം',
    value: 'ml',
  },
  {
    id: 'TAMIL',
    label: 'தமிழ்',
    value: 'ta',
  },
  {
    id: 'TELUGU',
    label: 'తెలుగు',
    value: 'te',
  },
  {
    id: 'MARATHI',
    label: 'मराठी',
    value: 'mr',
  },
  {
    id: 'BANGLA',
    label: 'বাংলা',
    value: 'bn',
  },
  {
    id: 'GUJARATI',
    label: 'ગુજરતી',
    value: 'gu',
  },
  {
    id: 'ENGLISH',
    label: 'English',
    value: 'en',
  },
]

export const mapCodeToLabelLanguage = {
  hi: 'HINDI' as const,
  en: 'ENGLISH' as const,
  gu: 'GUJARATI' as const,
  bn: 'BANGLA' as const,
  mr: 'MARATHI' as const,
  ml: 'MALAYALAM' as const,
  kn: 'KANNADA' as const,
  ta: 'TAMIL' as const,
  te: 'TELUGU' as const,
}

export type Locale = 'bn' | 'en' | 'gu' | 'hi' | 'kn' | 'ml' | 'mr' | 'ta' | 'te'

export const localeToLanguage = (locale: Locale) => mapCodeToLabelLanguage[locale] || mapCodeToLabelLanguage.en
